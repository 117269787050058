import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import './Header.css';
import { useAuth } from '../context/auth-context';
import { formatChainAsNum } from '../utils';

const Header = () => {
    const { metaMaskData, setMetaMaskData, logout } = useAuth();
    const [userName, setUserName] = useState('N/A');
    const [showDropdown, setShowDropdown] = useState(false);
    const [tokanData, setTokanData] = useState([]);
    const [walletBalance, setWalletBalance] = useState(Number);
    const [polygonPriceInUSD, setPolygonPriceInUSD] = useState(Number);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        metaMaskData.walletAddress && metaMaskData.walletAddress.length > 0
            ? setUserName(`${metaMaskData.walletAddress.substring(
                0,
                6
            )}...${metaMaskData.walletAddress.substring(38)}`) : setUserName('Connect Wallet');

        setTokanData(metaMaskData?.filteredTokanData);
        fetchEtherPrice();
    }, [metaMaskData.walletAddress, metaMaskData.filteredTokanData]);

    const toggleDropdown = () => setShowDropdown(prev => !prev);

    const fetchEtherPrice = async () => {
        if (formatChainAsNum(metaMaskData.chainId) === 137) {
            try {
                const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd');
                const data = await response.json();
                const etherPriceInUSD = data['matic-network'].usd;
                setWalletBalance(metaMaskData.walletBalance * etherPriceInUSD);
                setPolygonPriceInUSD(etherPriceInUSD.toFixed(4));
                setMetaMaskData(prevState => ({
                    ...prevState,
                    TotalwalletBalance: metaMaskData.walletBalance * etherPriceInUSD
                }));
            } catch (error) {
                console.error('Error fetching ether price:', error);
            }
        }
    };

    return (
        <header className="header">
            <div className="logo"><Link to="/">NASH-WERTHAN</Link></div>
            <nav className="nav-links"></nav>
            <div className="user-dropdown" ref={dropdownRef}>
                <DropdownButton
                    id="dropdown-basic-button"
                    title={'Wallet: ' + userName}
                    variant="success"
                    show={showDropdown}
                    onClick={toggleDropdown}
                >
                    {tokanData?.map(item => (
                        <React.Fragment key={item.chainId}>
                            <Dropdown.Item>
                                {item.name}: {metaMaskData.walletBalance ? metaMaskData.walletBalance : 'N/A'} {item.nativeCurrency.symbol}
                            </Dropdown.Item>
                            {/* {polygonPriceInUSD && ( */}
                            <Dropdown.Item>
                                {item.nativeCurrency.name} USD price: {polygonPriceInUSD ? polygonPriceInUSD : 'N/A'} $
                            </Dropdown.Item>
                            {/* )} */}
                        </React.Fragment>
                    ))}
                    <Dropdown.Item>
                        Wallet Balance: {walletBalance || metaMaskData.walletBalance || 'N/A'} {walletBalance ? '$' : ''}
                    </Dropdown.Item>
                    <Dropdown.Item>
                        Hex ChainId: {metaMaskData.chainId || 'N/A'}
                    </Dropdown.Item>
                    <Dropdown.Item>
                        Numeric ChainId: {metaMaskData.chainId ? formatChainAsNum(metaMaskData.chainId) : 'N/A'}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
                </DropdownButton>
            </div>
        </header>
    );
};

export default Header;
