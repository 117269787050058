export const formatBalance = (rawBalance) => {
    const balanceDecimal = parseInt(rawBalance, 16);
    const balanceEther = balanceDecimal / 1e18;
    return balanceEther.toFixed(2);
};

export const formatChainAsNum = (chainIdHex) => {
    const chainIdNum = parseInt(chainIdHex);
    return chainIdNum;
};
