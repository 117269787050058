import React from 'react'
import "./Loder.css"

function Loder() {
    return (
        <div className='mainLoder_div'>
            <div className="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default Loder;