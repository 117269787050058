import React, { useEffect } from "react";
import { toast } from "react-toastify";
import detectEthereumProvider from "@metamask/detect-provider";
import metamaskLogo from '../assets/MetaMask.png';
import { formatBalance, formatChainAsNum } from './utils';
import { useAuth } from "./context/auth-context";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import Web3 from 'web3';

const MetaMaskLogin = () => {
    const { setLoading, setIsAuthenticated, setMetaMaskData, logout } = useAuth();
    const navigate = useNavigate();
    const SECRET_KEY = '8hx(C#O6$RtRkV0Vcu^8J#^INVfHH!OC';

    useEffect(() => {
        const fetchData = async () => {
            const encrypted = localStorage.getItem('encryptedData');
            if (encrypted) {
                const decryptedBytes = CryptoJS.AES.decrypt(encrypted, SECRET_KEY);
                const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
                const decryptedData = JSON.parse(decryptedString);

                const currentTime = new Date().getTime();
                if (currentTime < decryptedData.expiry) {
                    setMetaMaskData(prevState => ({
                        ...prevState,
                        signature: decryptedData.signature,
                    }))
                    await fetchWalletInfo();
                } else {
                    localStorage.removeItem('encryptedData');
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    const fetchWalletInfo = async () => {
        try {
            const provider = await detectEthereumProvider();
            if (provider) {
                const accounts = await provider.request({ method: "eth_requestAccounts" });
                if (accounts.length > 0) {
                    const address = accounts[0];
                    const balance = await provider.request({
                        method: "eth_getBalance",
                        params: [address, "latest"],
                    });
                    const networkId = await provider.request({ method: "eth_chainId" });
                    const filteredTokanData = await fetchChainIdDetails(formatChainAsNum(networkId))
                    let tokenAddress = '0x1b32619aE71715cbD80303c380d0bFc418e8dba1'
                    const TokenDetails = await getTokenDetails(formatChainAsNum(networkId), tokenAddress, address)
                    const NFTsDetails = await fetchNFTsDetails(TokenDetails, tokenAddress)
                    setMetaMaskData(prevState => ({
                        ...prevState,
                        walletAddress: address,
                        walletBalance: formatBalance(balance),
                        chainId: networkId,
                        filteredTokanData,
                        TokenDetails,
                        NFTsDetails,
                    }));
                    setIsAuthenticated(true);
                    navigate('/Home');
                } else {
                    setIsAuthenticated(false);
                }
            } else {
                window.open("https://metamask.io/download/", "_blank");
            }
        } catch (error) {
            console.error("Error fetching wallet info:", error.message);
            const errorMessage = error.message.split(":")[1]?.trim();
            toast.error(errorMessage ? errorMessage : error.message);
            logout();
        } finally {
            setLoading(false);
        }
    };

    const loginWithMetaMask = async () => {
        setLoading(true);
        try {
            const provider = await detectEthereumProvider();
            if (provider) {
                const accounts = await provider.request({ method: "eth_requestAccounts" });
                if (accounts.length > 0) {
                    const address = accounts[0];
                    const message = `This request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n${address}`;
                    const signature = await provider.request({
                        method: "personal_sign",
                        params: [message, address],
                    });
                    const currentTime = new Date().getTime();
                    const encryptedData = { signature, expiry: currentTime + 24 * 60 * 60 * 1000 };
                    const encryptedString = JSON.stringify(encryptedData);
                    const encrypted = CryptoJS.AES.encrypt(encryptedString, SECRET_KEY).toString();
                    localStorage.setItem('encryptedData', encrypted);
                    const balance = await provider.request({
                        method: "eth_getBalance",
                        params: [address, "latest"],
                    });
                    // const nfts = await ethereum.request({
                    //     method: "eth_getNFTs",
                    //     params: [address],
                    // });
                    // console.log("nfts", nfts);
                    const networkId = await provider.request({ method: "eth_chainId" });
                    const filteredTokanData = await fetchChainIdDetails(formatChainAsNum(networkId))
                    let tokenAddress = '0x1b32619aE71715cbD80303c380d0bFc418e8dba1'
                    const TokenDetails = await getTokenDetails(formatChainAsNum(networkId), tokenAddress, address)
                    const NFTsDetails = await fetchNFTsDetails(TokenDetails, tokenAddress)
                    setMetaMaskData(prevState => ({
                        ...prevState,
                        walletAddress: address,
                        walletBalance: formatBalance(balance),
                        chainId: networkId,
                        signature,
                        filteredTokanData,
                        TokenDetails,
                        NFTsDetails,
                    }));
                    toast.success('Successfully logged in with MetaMask.')
                    setIsAuthenticated(true);
                    navigate('/Home');
                } else {
                    setIsAuthenticated(false);
                }
            } else {
                window.open("https://metamask.io/download/", "_blank");
            }
        } catch (error) {
            console.error("Error connecting to MetaMask:", error.message);
            const errorMessage = error.message.split(":")[1]?.trim();
            toast.error(errorMessage ? errorMessage : error.message);
            setIsAuthenticated(false);
        } finally {
            setLoading(false);
        }
    };

    async function getTokenDetails(chainId, tokenAddress, address) {
        let web3;
        if (chainId === 137) {
            web3 = new Web3('https://polygon-mainnet.infura.io/v3/e52c6944b6dc45c98c57b43a774d7df6');
        } else {
            console.error('Unsupported chain ID');
            return;
        }
        try {
            const response = await fetch(`https://nft-details-node-js.onrender.com/NFTs?tokenAddresses=${tokenAddress}&Walletaddress=${address}&chainID=${chainId}`)
            const tokenBasicDetails = await response.json();
            return tokenBasicDetails;
        } catch (error) {
            console.error('Error fetching token details:', error);
            return null;
        }
    }

    const fetchNFTsDetails = async (tokenBasicDetails, tokenAddress) => {

        if (tokenBasicDetails && tokenBasicDetails.data && tokenBasicDetails.data.result) {
            const ownedNFTs = [];
            for (const item of tokenBasicDetails.data.result) {
                try {
                    const NFTsAPIResponse = await fetch(`https://api.opensea.io/api/v2/chain/matic/contract/${tokenAddress}/nfts/${item.token_id}`, {
                        headers: {
                            'x-api-key': '2d308081fa6d4fe0909e52687b5242ac',
                            'Accept': 'application/json'
                        }
                    });
                    const NFTsAPIResponseData = await NFTsAPIResponse.json();
                    ownedNFTs.push(NFTsAPIResponseData);
                } catch (error) {
                    console.error('Error fetching data from second API:', error);
                }
            }
            // console.log("ownedNFTs", ownedNFTs);
            return ownedNFTs;
        }
    };

    const fetchChainIdDetails = async (chainId) => {
        try {
            const response = await fetch('https://chainid.network/chains_mini.json');
            const data = await response.json();
            const filteredTokanData = data.filter(token => token.chainId === chainId);
            return filteredTokanData;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="metamask-btn">
            <button onClick={loginWithMetaMask} style={styles.button}>
                <img src={metamaskLogo} alt="Metamask Logo" style={styles.logo} />
                Login with Metamask
            </button>
        </div>
    );
};

const styles = {
    button: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        borderRadius: '5px',
        backgroundColor: '#785f16',
        color: '#FFFFFF',
        border: 'none',
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        outline: 'none',
    },
    logo: {
        width: '30px',
        marginRight: '10px',
    },
};

export default MetaMaskLogin;

const tokenABI = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "balance",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "_approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]